<template>
	<div class="page_wrap">
		<top :bgWhite='true'></top>
		<div class="contentAll  padding-lr-30  top-80  padding-bottom-80 ">
			<div class=" bg-white padding content-box padding">
				<p class="fs16 bold text-333  solid-bottom padding-bottom-20">我的收益</p>
				<!-- 账户信息 -->
				<div class="account_info margin-tb-20 flex flex-direction justify-around padding-tb-10">
					<p class="account_title fs14  padding-left-10">余额</p>
					<p class="account_money fs20 padding-left-10">¥{{userMoney}}</p>
				</div>
				<!-- 账户明细 -->
				<div class="flex align-center padding-top-5 padding-bottom-10 solid-bottom" >
					<div class="padding-lr-20" v-for="(item,index) in tabList" :key='index'  @click="tabItem(item,index)">
						<span class="text-666 point fs12" :class="tabIndex==index?'actives_wallet':''">{{item.name}}</span>
					</div>
				</div>
				<!-- 账户明细 -->
				<account-detail v-if="tabIndex==0"></account-detail>
				<!-- 绑定账户 -->
				<bind-account v-if="tabIndex==1" class="margin-top-20"></bind-account>
				<!-- 提现 -->
				<apply-withdraw v-if="tabIndex==2" class="margin-top-20" :userMoney="userMoney" @cancel="cancelWithdraw" @applySuccess="applySuccess"></apply-withdraw>
				<!-- 收益说明 -->
				<withdraw-intro v-if="tabIndex==3"></withdraw-intro>
			</div>
		</div>
		<bottom :bgWhite='true'></bottom>
	</div>
</template>

<script>
	import accountDetail from '@/components/wallet/account-detail.vue'
	import bindAccount from '@/components/wallet/bind-account.vue'
	import applyWithdraw from '@/components/wallet/apply-withdraw.vue'
	import withdrawIntro from '@/components/wallet/withdraw-intro.vue'
	import top from '@/components/top'
	import bottom from '@/components/bottom'
	export default {
		components: {
			bottom,
			top,
			bindAccount,
			applyWithdraw,
			accountDetail,
			withdrawIntro
		},
		data(){
			return{
				userMoney:'0.00',
				tabIndex:0,
				tabList: [{
					name: '余额明细',
					id: 1,
				}, {
					name: '绑定账户',
					id: 2,
				}, {
					name: '申请提现',
					id: 3,
				},
				{
					name: '收益说明',
					id: 4,
				}],
				
			}
		},
		mounted() {
			this.getUserMoney()
		},
		methods:{
			// 申请提现成功
			applySuccess(){
				this.$router.go(-1);
			},
			// tab切换
			tabItem(item, index) {
				this.tabIndex = index
			},
			// 获取用户余额信息
			getUserMoney() {
				this.ajax('post', '/v1/5cc45274d6be9', {}, res => {
					if (res.code == 1) {
						this.userMoney = res.data.user_money;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
				
			},
			// 取消提现
			cancelWithdraw(){
				this.tabIndex = 0
			}
		}
	}
</script>

<style  lang="scss">
page,html{
	background: #F6F7F9 !important;
}
.page_wrap{
	background: #F6F7F9 !important;
	.content{
		width: 100%;
		background: #FFFFFF;
	}
}
.contentAll{
	width: 60%;
}
.content-box{
	width: 100%;
	.actives_wallet {
		position: relative;
		color: #333;
		font-weight: bold;
	}
	
	.actives_wallet::after {
		position: absolute;
		content: '';
		bottom: -10px;
		left: 0;
		right: 0;
		width: 30%;
		margin: auto;
		background-color: #E54F42;
		height: 3px;
		border-radius: 2px;
	}
	.account_info{
		width: 100%;
		height: 84px;
		background: rgba(229, 79, 66, 0.06);
		.account_title{
			font-size: 9px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			line-height: 16px;
			color: #333333;
			letter-spacing: 7px;
			opacity: 1;
		}
		.account_money{
			font-size: 20px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			line-height: 16px;
			color: #E54F42;
			opacity: 1;
		}
	}
	.log_list{
		width: 100%;
		min-height: 40px;
		background: #FFFFFF;
		opacity: 1;
		
	}
}

</style>