<template>
	<div>
		<div class="flex margin-top-30 align-center">
			<span class="text-333 fs9 margin-right-10">提现金额</span>
			<div class="flex align-center justify-center name-input">
				<el-input placeholder="请输入金额 0.00元" onkeyup="value=value.replace(/[^\d]/g,'')" min="0"  v-model="money" class="fs9 padding-lr-10" />
			</div>
			<p class="fs9 margin-left-10 text-blue point" @click="inputAll">全部提现</p>
		</div>
		<div class="margin-top-30">
			<div class="flex">
				<span class="text-333 fs9 margin-right-10">提现账号</span>
				<div class="">
					<div class="flex align-center ">
						<div class=" tabItem flex  align-center justify-center" :class="current==1?'selected':'normal'"
							@click="tabChange(1)">
							<img src="../../assets/image/icon/wechat.png" class="icon-img margin-right-5">
							<span class="text-666 point fs9">微信</span>
						</div>
						<div class=" tabItem flex  align-center justify-center" :class="current==2?'selected':'normal'"
							@click="tabChange(2)">
							<img src="../../assets/image/icon/alipay.png" class="icon-img margin-right-5">
							<span class="text-666 point fs9">支付宝</span>
						</div>
					</div>
					<div class="blue-dark fs9 margin-top-10">
						<i class="el-icon-warning-outline margin-right-5"></i>
						<span>注：提现金额将在24小时内转到提现账户请注意查收</span>
					</div>
				</div>
			</div>
			<!-- 按钮 -->
			<div class="flex justify-center margin-top-100 bottom-btn">
				<el-button type="info" round  plain size="medium" @click="cancelSubmit">取消</el-button>
				<el-button type="danger" round size="medium" @click="openPswModal">立即提现</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				pay_password:'', //支付密码
				money:'', //提现金额
				imageUrl: '',
				current: 1,
				tabLists: [{
					name: '微信',
					id: 1,
				}, {
					name: '支付宝',
					id: 2,
				}]
			}
		},
		props:{
			userMoney:{
				type:[String,Number],
				default:0
			}
		},
		methods: {
			// 打开密码输入框
			async openPswModal(){
				let bindFlag = await this.isBind();
				if(bindFlag==0){
					this.$message.error('请先绑定提现账号');
					return false;
				}
				let isSetPayPassword = await this.isSetPayPassword()
				if(!isSetPayPassword){
					this.$message.error('请先设置支付密码');
					// todo  跳转到设置支付密码页面
					return false;
				}
				if(!this.money){
					this.$message.error('请输入提现金额');
					return false;
				};
				this.$prompt('请输入支付密码', '提示', {
				  confirmButtonText: '确定',
				  cancelButtonText: '取消',
				  inputPattern: /^\d{6}$/,
				  inputErrorMessage: '请输入6位数字支付密码',
				  inputType:'password'
				}).then(({ value }) => {
				  this.pay_password = value;
				  this.submitWithdraw()
				}).catch(() => {
				  this.$message({
					type: 'info',
					message: '取消输入'
				  });       
				});
			},
			// 全部提现
			inputAll(){
				this.money = this.userMoney
			},
			tabChange(index) {
				this.current = index
			},
			// 取消提现
			cancelSubmit(){
				this.$emit('cancel')
			},
			/**
			 * @function
			 * @description 判断用户是否绑定账户
			 */
			isBind(){
				return new Promise((resolve,reject)=>{
					this.ajax('get', '/v1/5d7b9bd1c7d7c?account_type='+this.current, {}, res => {
						resolve(res.code)
					}, err => {
						console.log(err);
					})
				})
			},
			/**
			 * @function
			 * @description 判断用户是否设置支付密码
			 * @property {Number}  - 5f64a4d364b44
			 */
			isSetPayPassword() {
			  return new Promise(resolve => {
				  this.ajax('get', '/v1/5f64a4d364b44', {}, res => {
				  	if (res.code == 1) {
				  	  if (res.data.falg == 0) {
				  	    resolve(false);
				  	  } else {
				  	    resolve(true);
				  	  }
				  	} else {
				  	  this.$message.info(res.msg);
				  	  resolve(false);
				  	}
				  }, err => {
				  	console.log(err);
				  })
			  });
			},
			// 立即提现
			submitWithdraw(){
				this.ajax('post', '/v1/5ce25d5e1ffb8', {
					money:this.money,
					type:this.current,
					pay_password:this.pay_password
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
						this.$emit('applySuccess')
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.icon-img {
		width: 22px;
		height: 17px;
	}

	.tabItem {
		width: 120px;
		height: 40px;
		opacity: 1;
		border-radius: 3px;
		margin-right: 10px;
	}

	.selected {
		background: #F6FFF4;
		border: 1px solid #52BD33;
	}

	.normal {
		border: 1px solid #EEEEEE;
	}

	.name-input {
		input {
			width: 213px;
			height: 27px;
			border: 1px solid #BFBFBF;
			opacity: 1;
			border-radius: 3px;
		}

	}
	.text-blue{
		color: #4177DD;
	}
	.blue-dark{
		color: #3D5676;
	}
	.bottom-btn{
		button{
			width: 112px;
			height: 40px;
		}
	}
</style>
