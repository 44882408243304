<template>
	<div>
		<div class="">
			<div class="">
				<el-row :gutter="24">
					<el-col :span="4" class="text-right">
						<span class="text-333 fs9 ">绑定账号</span>
					</el-col>
					<el-col :span="20">
						<div class="flex align-center ">
							<div class=" tabItem flex  align-center justify-center" :class="current==1?'selected':'normal'"
								@click="tabChange(1)">
								<img src="../../assets/image/icon/wechat.png" class="icon-img margin-right-5">
								<span class="text-666 point fs9">微信</span>
							</div>
							<div class=" tabItem flex  align-center justify-center" :class="current==2?'selected':'normal'"
								@click="tabChange(2)">
								<img src="../../assets/image/icon/alipay.png" class="icon-img margin-right-5">
								<span class="text-666 point fs9">支付宝</span>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
			<div class=" margin-tb-20 ">
				<el-row :gutter="24">
					<el-col :span="4" class="text-right">
						<span class="text-333 fs9 ">姓名</span>
					</el-col>
					<el-col :span="20" class="">
						<div class="flex align-center  name-input">
							<input placeholder="请输入真实姓名，需与认证信息一致" maxlength="5" v-model="true_name" class="fs9 padding-lr-10" />
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="">
				<el-row :gutter="24">
					<el-col :span="4" class="text-right">
						<span class="text-333 fs9">微信收款二维码</span>
					</el-col>
					<el-col :span="20">
						<div class="flex flex-direction  justify-center justify-center">
							<div class="block-95">
								<el-upload class="avatar-uploader" :action="BaseUrl+'/api/v1/5fc615f7da92b'"
									:show-file-list="false" :on-success="handleAvatarSuccess"
									:before-upload="beforeAvatarUpload">
									<img v-if="qrcode" :src="qrcode" class="avatar">
									<i v-else class="el-icon-plus avatar-uploader-icon block-95"></i>
								</el-upload>
						
							</div>
							<p class="margin-top-10 fs9 block-95 text-center">上传二维码</p>
						</div>
					</el-col>
				</el-row>
				
			</div>
			<!-- 按钮 -->
			<div class="flex justify-center ">
				<el-button type="danger" round @click="confirmBind">确定</el-button>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				BaseUrl:this.imgUrl,
				qrcode: '',
				current: 1,//账户类型 1 微信 2 支付宝 3 银行卡
				true_name:'', //真实姓名
				tabLists: [{
					name: '微信',
					id: 1,
				}, {
					name: '支付宝',
					id: 2,
				}]
			}
		},
		mounted(){
			this.getBindInfo()
		},
		methods: {
			tabChange(index) {
				if(this.current == index) return;
				this.current = index;
				this.getBindInfo()
			},
			handleAvatarSuccess(res, file) {
				this.qrcode = res.data.data.fileId;
			},
			beforeAvatarUpload(file) {
				const isJPG = file.type === 'image/jpeg';
				const isLt2M = file.size / 1024 / 1024 < 2;

				if (!isJPG) {
					this.$message.error('上传头像图片只能是 JPG 格式!');
				}
				if (!isLt2M) {
					this.$message.error('上传头像图片大小不能超过 2MB!');
				}
				return isJPG && isLt2M;
			},
			// 获取已绑定账户信息
			getBindInfo(){
				this.ajax('get', '/v1/5d7b9bd1c7d7c?account_type='+this.current, {}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.true_name = res.data.true_name;
						this.qrcode = res.data.qrcode;
						this.is_bind = true;
					} else {
						this.true_name ='';
						this.qrcode ='';
					}
				
				}, err => {
					console.log(err);
				})
			},
			// 确认绑定
			confirmBind(){
				if(!this.true_name){
					this.$message.error('请先输入真实姓名');
					return;
				}
				if(!this.qrcode){
					this.$message.error('请先上传收款二维码');
					return;
				}
				this.ajax('post', '/v1/5d7b7d4007529', {
					true_name:this.true_name,
					qrcode:this.qrcode,
					account_type:this.current,
					account_id:this.current
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.$message.success(res.msg);
						this.$router.go(-1)
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	.icon-img {
		width: 22px;
		height: 17px;
	}

	.tabItem {
		width: 120px;
		height: 40px;
		opacity: 1;
		border-radius: 3px;
		margin-right: 10px;
	}

	.selected {
		background: #F6FFF4;
		border: 1px solid #52BD33;
	}

	.normal {
		border: 1px solid #EEEEEE;
	}

	.name-input {
		input {
			width: 320px;
			height: 40px;
			border: 1px solid #BFBFBF;
			opacity: 1;
			border-radius: 3px;
		}

	}

	.avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 143px;
		height: 143px;
		line-height: 95px;
		text-align: center;
		border: 1px dashed #EBEEF5;
		background: #FCFCFC;
	}

	.avatar {
		width: 95px;
		height: 95px;
		display: block;
	}
</style>
