<template>
	<div class="margin-top-20">
		<!-- 加载中 -->
		<div class="w100" v-if="!isInit">
			<el-skeleton :rows="4" animated />
		</div>
		<div class="" v-else>
			<div class="lists " v-if="lists.length>0">
				<div class="list_item padding-lr-20" v-for="(item,index) in lists" :key="index">
					<el-row :gutter="24">
						<el-col :span="8">
							<div class="flex align-center">
								<p class="fs9">{{item.remark}}</p>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="flex align-center">
								<p class="fs9">{{item.change_money}}</p>
							</div>
						</el-col>
						<el-col :span="8">
							<div class="flex align-center">
								<p class="fs9">{{item.create_time}}</p>
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="" v-if="lists.length==0">
				<el-empty description="~空空如也~"></el-empty>
			</div>
		</div>
		<div class="flex align-center justify-center margin-top-30">
			<el-pagination background  layout="prev, pager, next" :total="totalSize" :page-size='15' :hide-on-single-page='false' @current-change="currentChange">
			</el-pagination>
		</div>
		
	</div>
</template>

<script>
	export default {
		data(){
			return{
				isInit:false, //是否加载完毕
				totalSize:0, //总页数
				lists:[],
				page:1
			}
		},
		mounted() {
			this.loadData()
		},
		methods:{
			// 点击当前页
			currentChange(e){
				this.page = e;
				this.loadData()
			},
			// 获取列表
			loadData() {
				this.ajax('post', '/v1/5cc45422e5c87', {
					page:this.page,
					type:0
				}, res => {
					this.isInit = true;
					if (res.code == 1) {
						this.totalSize = res.data.total;
						this.lists =  res.data.data;
					} else {
						this.$message.error(res.msg);
					}
				
				}, err => {
					console.log(err);
				})
				
			}
		}
	}
</script>

<style scoped>
.list_item{
	height: 60px;
	line-height: 60px;
	font-size: 9px;
	border-bottom: 1px dashed  #EEEEEE;
}
</style>