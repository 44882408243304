<template>
	<div class="padding">
		<p v-html="content"></p>
	</div>
</template>

<script>
	export default {
		data(){
			return{
				content:'加载中...' //提现说明
			}
		},
		created() {
			this.getPageInfo()
		},
		methods:{
			// 获取收益说明
			getPageInfo() {
				this.ajax('post', '/v1/61613cd15b28d', {
					category_id:28
				}, res => {
					if (res.code == 1) {
						if(res.data.content){
							this.content = res.data.content
						}else{
							this.content = '暂无相关内容'
						}
					} else {
						this.content = '暂无相关内容'
					}
				
				}, err => {
					console.log(err);
				})
				
			},
		}
	}
</script>

<style scoped>

</style>